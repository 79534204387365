import Storage from './storage'

let storage = new Storage()

const KEY = 'company'

export function getCompanyData()
{
    return [
        {id: 1, name: '樱桃推广'},
        {id: 2, name: '樱桃咨询'},
    ]
}

/**
 *
 * @returns {string|*}
 */
export function getCompany()
{
    return storage.get(KEY)
}

/**
 *
 * @param data
 */
export function setCompany(data)
{
    storage.set(KEY, data)
}

/**
 *
 * @returns {*|number}
 */
export function getCompanyId() {
    let company = getCompany()

    return company ? company.id : 1
}
