import {httpDelete, httpGet, httpPost, httpPut} from '@/plugins/request'


/**
 * 查看项目预算
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjectBudgets(query = [])
{
    return httpGet('/manage/project/budget', query)
}

/**
 * 新增项目预算
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiStoreProjectBudget(data)
{
    return httpPost('/manage/project/budget', data)
}

/**
 * 修改项目预算
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiUpdateProjectBudget(id, data)
{
    return httpPut(`/manage/project/budget/${id}`, data)
}


/**
 * 删除项目预算
 *
 * @param id
 * @returns {Promise<*>}
 */
export function apiDestroyProjectBudget(id)
{
    return httpDelete(`/manage/project/budget/${id}`)
}

/**
 * 查看预算计算结果
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiViewCalcProjectBudget(data)
{
    return httpPost(`/manage/project/budget/view`, data)
}
