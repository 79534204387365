<template>
  <div>

    <!----- 搜索框 start------->
    <el-form :inline="true" class="search">
      <el-form-item style="float: right">
        <el-button @click.stop="clickClear()">清空数量</el-button>
        <el-button @click.stop="clickView()">预算预览</el-button>
        <el-button type="primary" @click.stop="clickCreate()">添加预算</el-button>
      </el-form-item>
    </el-form>
    <!----- 搜索框 end------->

    <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        default-expand-all
        :tree-props="{children: 'children'}">
      <el-table-column
          prop="name"
          label="预算项">
      </el-table-column>
      <el-table-column label="数量" width="150">
        <template #default="scope">
          <el-input type="number" v-show="scope.row.amount > 0" placeholder="不填不提交" v-model="viewData.data.data[scope.row.id.toString()]" />
        </template>
      </el-table-column>
      <el-table-column
          label="单价">
        <template #default="scope">
          <span v-show="scope.row.amount > 0" v-text="scope.row.amount"></span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="warning" size="small" @click="clickUpdate(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="clickDestroy(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="formData.show" title="编辑预算" fullscreen append-to-body>
      <el-form
          ref="budget"
          :rules="formData.rules"
          label-width="100px"
          :model="formData.data"

      >
        <el-form-item label="预算名称" prop="name">
          <el-input v-model="formData.data.name" placeholder="输入预算名称" />
        </el-form-item>

        <el-form-item label="上级" prop="parent_id">
          <el-select v-model="formData.data.parent_id" placeholder="请选择">
            <el-option label="顶级" :value="0"></el-option>
            <el-option
                v-for="item in parentsData"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="预算金额" prop="amount">
          <el-input type="number" v-model="formData.data.amount" placeholder="输入预算总金额" />
        </el-form-item>

        <el-form-item label="预算数量" prop="number">
          <el-input v-model="formData.data.number" type="number" placeholder="输入预算总数量" />
        </el-form-item>

        <el-form-item label="排序" prop="sort">
          <el-input v-model="formData.data.sort" type="number" placeholder="越大越前" />
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeCreate">取消</el-button>
          <el-button type="primary" @click="submitBudget">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->


    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="viewData.show" title="预算预览" fullscreen append-to-body>
      <h3>合同金额
        <el-input-number width="100px" :min="0" v-model="viewData.contract_price" @change="calcContractPrice"></el-input-number>
        ，预算合计：
        <span v-text="viewData.total"></span>
        ，利润：<span v-text="viewData.rate"></span>
      </h3>
      <el-table
          :data="viewData.tableData"
          row-key="id"
          border
          default-expand-all
          :tree-props="{children: 'children'}">
        <el-table-column
            prop="name"
            label="预算项">
        </el-table-column>
        <el-table-column label="数量">
          <template v-slot="scope">
            <b>{{ scope.row.number}}</b>
          </template>
        </el-table-column>
        <el-table-column label="单价" prop="amount"></el-table-column>
        <el-table-column label="总价" prop="total"></el-table-column>
      </el-table>

      <br><br>

      <el-divider>添加预算到合同</el-divider>

      <el-form
          ref="add-contract"
          :rules="viewData.rules"
          :model="viewData.data"
          :inline="true"
      >
        <el-form-item label="合同" prop="name" style="width:50%">
          <el-select v-model="viewData.data.project_contract_id" filterable placeholder="请选择合同"  style="width:100%">
            <el-option
                v-for="item in contracts"
                :key="item.id"
                :label="item.project.name + item.remark + ' ('+ item.start_at + '~' + item.end_at +')'"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitContractBudget">提交</el-button>
          <el-button type="primary" @click="updateContractBudget">更新</el-button>
        </el-form-item>

      </el-form>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->
  </div>
</template>

<script>
import {
  apiDestroyProjectBudget,
  apiGetProjectBudgets,
  apiStoreProjectBudget,
  apiUpdateProjectBudget,
  apiViewCalcProjectBudget
} from "@/api/projectBudgetApi"
import {apiGetProjectContracts, apiStoreProjectContractBudget} from "@/api/projectContractApi"
import {getCompanyId} from "@/plugins/company"

const formData = {
  company_id: getCompanyId(),
  project_contract_id: 0,
  parent_id: null,
  name: '',
  amount: null,
  number: null,
  sort: 0,
}

  export default {
    name: 'project.budget-template',
    created() {
      this.getBudgets()
      this.getContracts()
    },
    data() {
      return {
        contracts: [],
        parentsData: [],
        tableData: [],
        formData: {
          id: 0,
          show: false,
          data: formData,
          rules: {
            name: [{
              required: true,
              message: '请输入预算名称',
              trigger: 'blur',
            }],
            parent_id: [{
              required: true,
              message: '请选择上级预算',
              trigger: 'blur',
            }],
            amount: [{
              required: true,
              message: '请输入预算金额',
              trigger: 'blur',
            }],
            number: [{
              required: true,
              message: '请输入预算数量',
              trigger: 'blur',
            }],
          }
        },
        viewData: {
          tableData: [],
          show: false,
          total: 0,
          contract_price: 0,
          rate: '',
          data: {project_contract_id: '', data: []},
          rules: {project_contract_id: [{
              required: true,
              message: '请选择合同',
              trigger: 'blur',
            }]}
        }
      }
    },
    methods: {
      getBudgets () {
        apiGetProjectBudgets({
          company_id: getCompanyId(),
          project_id: 0,
          project_contract_id: 0,
          parent_id: 0,
          with_query: ['child'],
          per_page: 1000,
        }).then(rsp => {
          this.tableData = rsp.data
        })
      },
      // 获取上级预算
      getParentBudgets () {
        apiGetProjectBudgets({
          company_id: getCompanyId(),
          project_id: 0,
          project_contract_id: 0,
          parent_id: 0,
          per_page: 1000,
        }).then(rsp => {
          this.parentsData = rsp.data
        })
      },
      // 获取合同列表
      getContracts(force = false) {
        if (!force && this.contracts.length > 0) {
          return
        }
        apiGetProjectContracts({per_page: 1000, status: 1, with_query: ['project'], company_id: getCompanyId(),}).then(res => {
          this.contracts = res.data
        })
      },
      // 提交预算修改
      submitBudget () {
        if (this.formData.lock) {return;}
        this.formLock()
        this.$refs['budget'].validate(valid => {
          if (valid) {
            if (this.formData.id) {
              apiUpdateProjectBudget(this.formData.id, this.formData.data).then(() => {
                this.getBudgets()
                this.$message.success('预算修改成功')
                this.closeCreate()
                this.formUnlock()
              }).catch(() => {
                this.formUnlock()
              })
            } else{
              apiStoreProjectBudget(this.formData.data).then(() => {
                this.getBudgets()
                this.$message.success('预算新增成功')
                this.closeCreate()
                this.formUnlock()
              }).catch(() => {
                this.formUnlock()
              })
            }
          }
        })
      },
      // 添加预算到合同
      submitContractBudget () {
        this.$confirm('确定将以上预算添加到合同吗').then(() => {
          apiStoreProjectContractBudget(this.viewData.data.project_contract_id, this.viewData.data).then(() => {
            this.$message.success('添加成功')
          })
        })
      },
      updateContractBudget () {
        this.getContracts(true)
      },
      formLock() {
        this.formData.lock = true
      },
      formUnlock() {
        this.formData.lock = false
      },
      // 预算预览
      clickView () {
        if (this.viewData.data.data.length <= 0) {
          this.$message.warning('请输入预算数量！')
          return
        }
        apiViewCalcProjectBudget(this.viewData.data).then(rsp => {
          this.viewData.tableData = rsp.result
          this.viewData.total = rsp.total
          this.viewData.show = true
        })
      },
      // 清空数据
      clickClear () {
        this.$confirm('确定清空吗').then( () => {
          this.viewData.data.data = []
        })
      },
      clickCreate () {
        this.getParentBudgets()
        this.formData.id = 0
        this.formData.show = true
      },
      clickUpdate (budget) {
        this.getParentBudgets()
        this.formData.id = budget.id
        this.formData.data = budget
        this.formData.show = true
      },
      clickDestroy (budget) {
        this.$confirm('确定删除吗？').then(() => {
          apiDestroyProjectBudget(budget.id).then(() => {
            this.getBudgets()
            this.$message.success('删除成功')
          })
        })
      },
      closeCreate () {
        this.formData.show = false
      },
      calcContractPrice (value) {
        if (value > 0 && value > this.viewData.total) {
          let price = value - this.viewData.total
          let rate = (price / value) * 100
          this.viewData.rate = price.toFixed(2) + '（' + rate.toFixed(3) + '%）'
        }
      }
    }
  }
</script>
