import {httpGet, httpPost, httpPut} from '@/plugins/request'


/**
 * 项目合同列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjectContracts(query = [])
{
    return httpGet('/manage/project/contract', query)
}

/**
 * 新增项目合同
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiStoreProjectContract(data)
{
    return httpPost('/manage/project/contract', data)
}

/**
 * 批量新增项目合同预算
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiStoreProjectContractBudget(id, data)
{
    return httpPost(`/manage/project/contract/${id}/budget`, data)
}

/**
 * 修改项目合同
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiUpdateProjectContract(id, data)
{
    return httpPut(`/manage/project/contract/${id}`, data)
}

/**
 * 项目合同详情
 * @param id
 * @param query
 * @returns {Promise<*>}
 */
export function apiShowProjectContract(id, query)
{
    return httpGet(`/manage/project/contract/${id}`, query)
}

/**
 * 启用或禁用
 *
 * @param id
 * @returns {Promise<*>}
 */
export function apiDisableProjectContract(id)
{
    return httpPost(`/manage/project/contract/${id}/disable`);
}


/**
 * 标记是否续费
 *
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiRenewProjectContract(id, data)
{
    return httpPost(`/manage/project/contract/${id}/renew`, data);
}
